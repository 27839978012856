import LandingPage from './components/LandingPage';
import TemplateEmail from './components/Template_welcome_email'


function App() {
  return (  
     <LandingPage />
      // <TemplateEmail />
  );
}

export default App;
