import { useState } from "react"


export const Email = () => {

    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
  

    function handleChange(e){
        setEmail(e.target.value)
    }

    const checkValidation = (e) => {  
        e.preventDefault()
        const rgExp = /^[a-zA-Z0-9._]+@[a-z]+\.[a-z]{2,6}$/
        if(rgExp.test(email)){
            setMessage("Gracias por contactarte, Nos veremos pronto!")  
            setEmail("")
            setTimeout(() => {
                setMessage("")                            
              }, 4000);          
        } else if (email === ""){
            setMessage("Debe ingresar un correo electrónico")
            setTimeout(() => {
                setMessage("")                                       
              }, 2000);            
            
        } else if (!rgExp.test(email)){
            setMessage("El email no es valido")
            setTimeout(() => {
                setMessage("")                            
              }, 2000);
           
        } else {
            setMessage("")           
        }
    }

        return   (       
            <div>
               <div className="flex w-3/4  mx-auto pt-6 sm:ml-20">
                    <input type="email" value={email} onChange={handleChange} placeholder="Ingrese correo electrónico" className="text-[14px] p-2 rounded-l-md focus:outline-none px-2 w-full" required />
                    <button onClick={checkValidation} className="text-[14px] bg-gray-800 text-white rounded-r-md hover:bg-gray-900 
                    hover:-translate-y-1 ease-linear  transition-all focus:outline-none px-4 py-1 sm:py-2">Suscríbete</button>                
               </div>  

                    { message === "" ? <div> </div> :  <div className="bg-[#F97068]  w-3/4 mx-auto mt-5 transition-all rounded-md sm:ml-20 border border-white"> 
                        <p className="mtext-white text-[12px] p-1.5 w-3/4 
                    mx-auto sm:ml-2  text-white  font-medium"> {message}</p>                
                    </div>}          
            </div>           
    )           
}

