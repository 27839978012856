// src/components/LandingPage.js

import React from 'react';
import { Email } from './Emailvalidation';


const LandingPage = () => {
    return (
       
        <div className=' bg-[#F97068] min-h-screen sm:flex '>
            <div className=" text-center  sm:w-1/2 sm:text-left  ">
                <h1 className="text-white text-[65px] sm:text-[80px] sm:ml-20 sm:mt-20 font-poppins leading-[80px] tracking-[4px] pt-8 ">
                    XERVIX
                </h1>
                
                <h2 className="text-white text-[30px] font-poppins pt-6 sm:ml-20 sm:text-[35px] sm:pt-8">
                    Muy pronto!</h2>
                <p className="text-white text-[11px] pt-6 w-3/4  mx-auto sm:ml-20 sm:text-[15px]">
                ¡Prepárate! Algo realmente genial está por llegar!</p>
                <p className="text-white text-[11px] pt-6 w-3/4  mx-auto sm:ml-20 sm:text-[12px]">                    
                    Suscribete y enterate de mas</p>
                <Email/>          

            </div>

            <div className="sm:w-1/2 w-full sm:content-center">
                <img src="/assets/img/cohete-nubes.png" alt="Rocket" className="w-80 mx-auto sm:w-3/4" />
                <div className="lg:absolute lg:left-20 lg:bottom-40">
                    <div className="flex content-center justify-center w-full lg:justify-between">
                        <a href="#"><img src="/assets/img/facebook-icon.svg" alt="Facebook" className="w-5 mr-2 " /></a>
                        <a href="#"><img src="/assets/img/twitter-icon.svg" alt="Twitter" className="w-5 mr-2" /></a>
                        <a href="#"><img src="/assets/img/instagram-icon.svg" alt="Instagram" className="w-5 mr-2" /></a>
                        <a href="#"><img src="/assets/img/linkedin-icon.svg" alt="LinkedIn" className="w-5 mr-2" /></a>
                        <a href="#"><img src="/assets/img/github-icon.svg" alt="GitHub" className="w-5 mr-2" /></a>
                    </div>                
                <p className="text-white text-[11px] pt-4 w-full text-center ">© Copyrights Xervix | All Rights Reserved</p>
            </div>       
            </div>
            
        </div>
    );
};

export default LandingPage;
